





















































import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'
import { Loader } from '../../../shared/molecules/Loader'
import { ProductCard } from '../../../products/organisms/ProductCard'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'

import { CartItem } from '../../molecules/CartItem'
import { CartItemProps } from '../../molecules/CartItem/CartItem.contracts'
import { Inject } from '../../../../support'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { RouteName as CheckoutRoutes } from '../../routes'
import { toImageProps } from '../../../shared/support'
import { AllowedImageRatio, AllowedImageWidth } from '../../../../contexts'
import { SuggestedProductsSource } from '../../../shared/contracts/suggestedProducts'
import {
  ADDED_TO_CART_DRAWER_COMPONENT_KEY,
  ADDED_TO_CART_DRAWER_COMPONENT_CONFIG_MAP
} from './AddedToCartDrawer.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { DynamicContentAction } from '../../../analytics/events/dynamicContent.event'
import { DynamicHelperMixin } from '../../../shared/mixins/dynamicHelper.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AddedToCartDrawer>({
  name: 'AddedToCartDrawer',
  components: {
    CartItem,
    Loader,
    ProductCard
  },
  created () {
    this.config = this.getComponentConfig(
      ADDED_TO_CART_DRAWER_COMPONENT_KEY,
      ADDED_TO_CART_DRAWER_COMPONENT_CONFIG_MAP
    )

    if (!this.inBundles) {
      this.handleSuggestedProductsLoad(
        this.payload.product.sku,
        SuggestedProductsSource.AddToCartModal,
        RecommendationAction.BrandAndCategory,
        this.loadSuggestedProductsByDelivery
      )
    } else {
      this.loadSuggestedProducts(this.payload.product.bundledSiblings)
    }
  },
  mounted () {
    this.eventBus.handle('app:mobile-nav-click', (event: EventPayload<string>) => {
      if (event.payload) {
        this.close()
      }
    })

    this.initDynamicEventsData()
    this.setAppropriateDrawerHeight()
  }
})
export class AddedToCartDrawer extends Mixins(
  AbstractDrawer,
  SuggestedProductsMixin,
  StructureConfigurable,
  DynamicHelperMixin
) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Ref('suggestedProductsContainer')
  public suggestedProductsRef?: HTMLDivElement

  public windowHeight: number | null = null

  public get loadSuggestedProductsByDelivery (): boolean {
    return this.getConfigProperty('loadSuggestedProductsByDelivery')
  }

  public get shouldSendDynamicContentEvents (): boolean {
    return this.getConfigProperty('shouldSendDynamicContentEvents')
  }

  public get productItem (): CartItemProps {
    return {
      description: this.payload.product.title ?? '',
      price: this.payload.product.price,
      previousPrice: this.payload.product.previousPrice,
      thumbnail: toImageProps(
        this.payload.product.image,
        AllowedImageRatio.Square,
        AllowedImageWidth.ProductCardSmall
      ),
      title: this.payload.product.brand ?? '',
      enhancedDesc: `${this.payload.product.category ?? ''} ${this.payload.product.volume ?? ''}`,
      properties: [],
      isDisabled: true
    }
  }

  public get inBundles (): boolean {
    return Array.isArray(this.payload.product.bundledSiblings) && this.payload.product.bundledSiblings.length > 0
  }

  /**
   * Redirect user to cart page.
   */
  public async goToCart (): Promise<void> {
    await this.$router.push({ name: `checkout.${CheckoutRoutes.Cart}` })
    this.close()
  }

  public onProductCardClick (): void {
    if (this.dynamicContentData) {
      this.triggerDynamicContentEvent(this.dynamicContentData, DynamicContentAction.Click)
    }
    this.close()
  }

  /**
   * Composes a content for dynamic data events
   * @see DynamicContent
   */
  public initDynamicEventsData (): void {
    if (!this.shouldSendDynamicContentEvents) {
      return
    }

    this.composeDynamicContentData(
      this.$t('front.products.organisms.addedToCartModal.suggestedProductsLabel').toString(),
      SuggestedProductsSource.AddToCartModal
    )
  }

  @Watch('suggestedProducts')
  public onSuggestedProductsRefsUpdate () {
    this.$nextTick(() => {
      if (!this.suggestedProductsRef) {
        return
      }
      this.handleContentShow(this.suggestedProductsRef, false)
    })
  }

  protected setAppropriateDrawerHeight (): void {
    const drawer: HTMLDivElement | null = document.querySelector('.drawer')
    const drawerContent: HTMLDivElement | null = document.querySelector('.drawer .content')

    if (drawer && drawerContent && this.payload.drawerHeight) {
      drawerContent.style.setProperty('max-height', `${this.payload.drawerHeight}px`)
      drawerContent.style.setProperty(
        'bottom',
        `${this.payload.mobileNavHeight ? this.payload.mobileNavHeight : 0}px`
      )
      drawer.style.setProperty('max-height', `${this.payload.drawerHeight}px`)
      drawer.style.setProperty('overflow-x', 'hidden')
      drawer.style.setProperty(
        'bottom',
        `${this.payload.mobileNavHeight ? this.payload.mobileNavHeight : 0}px`
      )
    }
  }
}

export default AddedToCartDrawer
