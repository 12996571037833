export const ADDED_TO_CART_DRAWER_COMPONENT_KEY = 'AddedToCartDrawerComponent'

export interface AddedToCartDrawerConfig {
  loadSuggestedProductsByDelivery: boolean
  shouldSendDynamicContentEvents: boolean
}

export const ADDED_TO_CART_DRAWER_COMPONENT_CONFIG_MAP: AddedToCartDrawerConfig = {
  loadSuggestedProductsByDelivery: false,
  shouldSendDynamicContentEvents: false
}
